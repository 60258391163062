
import { Component, mixins } from 'nuxt-property-decorator';
import DeviceMixin from '~/mixins/device.mixin';
import CardCarouselMixin from '~/mixins/card-carousel.mixin';
import WidgetMixin from '~/mixins/widget.mixin';

@Component({
  name: 'dn-product-carousel',
})
export default class ProductCarousel extends mixins(
  DeviceMixin,
  CardCarouselMixin,
  WidgetMixin,
) {}
