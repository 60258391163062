
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import Product from '~/models/product';
import { CategoryDataInterface } from '~/models/category-data.interface';
import { BADGES_TYPE } from '~/constants/badges';
import { plainToInstance } from 'class-transformer';
import Photo from '~/models/photo';

@Component({
  name: 'dn-product-card',
})
export default class ProductCard extends Vue {
  @Prop({ type: Object, required: true }) product!: Product;
  @Prop({ type: Boolean, default: false }) condensed!: boolean;
  @Prop({ type: Object, default: null }) category!: CategoryDataInterface;
  @Prop({ type: Boolean, default: false }) showMoreCard!: boolean;
  @Prop({ type: String, default: '' }) showMoreRedirectUrl!: string;
  @Prop({ type: Boolean, default: false }) showCustomBackground!: boolean;
  @Prop({ type: Boolean, default: false }) showAlternativeInfo!: boolean;
  @Prop({ type: Boolean, default: false }) hideInfos!: boolean;
  @Prop({ type: Boolean, default: false }) showPriceSpace!: boolean;
  @Prop({ type: Boolean, default: false }) specialStyles!: boolean;
  @Prop({ type: Boolean, default: false }) disableHover?: boolean;

  private BADGES_TYPE = BADGES_TYPE;

  private get isAlcohol(): boolean {
    return this.product?.details?.isAlcohol || false;
  }

  private get backgroundUrl(): string | undefined {
    return this.photos?.find(photo => photo.backgroundImage)?.file?.url;
  } 

  private get photos(): Photo[] | undefined {
    return this.product?.photos?.map((item) => plainToInstance(Photo, item));
  }

  private get productPhoto(): Photo | undefined {
    const productPhotos = this.photos?.filter((photo) => !photo.backgroundImage);

    if (this.showCustomBackground) {
      return productPhotos?.find(photo => photo.heroBanner) || productPhotos?.[0];
    }

    return productPhotos?.find(photo => photo.main) || productPhotos?.[0];
  }

  private get name(): string {
    if (this.showAlternativeInfo && this.product?.details?.nameList) {
      return this.product?.details?.nameList || '';
    }
    
    return this.product?.details?.name || '';
  }

  private get description(): string {
    if (this.showAlternativeInfo && this.product?.details?.descriptionList ) {
      return this.product?.details?.descriptionList || '';
    }

    return this.product?.details?.shortDescription || '';
  }
}
