
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import Widget from '~/models/widget';
import ProductResults from '~/models/product-results';

@Component({
  name: 'dn-product-group-list',
  computed: {
    ProductResults() {
      return ProductResults;
    },
  },
})
export default class ProductGroupList extends Vue {
  @Prop({ type: Object, required: true }) data!: Widget;

  get products() {
    return (this.data.results as ProductResults)?.products;
  }
}
