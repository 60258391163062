var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nuxt-link',{staticClass:"w-full h-full p-2 lg:p-3 rounded-[15px] md:rounded-[18px] lg:rounded-[26px] bg-white shadow-md overflow-hidden",class:{ 
    'show-more-card': _vm.showMoreCard, 
    'transition-transform md:hover:scale-[105%] will-change-transform duration-200': !_vm.disableHover,
    flex: _vm.hideInfos,
    'bg-none': _vm.showCustomBackground && _vm.backgroundUrl,
  },attrs:{"to":_vm.showMoreCard && _vm.showMoreRedirectUrl
      ? _vm.showMoreRedirectUrl
      : _vm.localePath({
          name: 'products-slug',
          params: { slug: _vm.product?.slug },
          query: {
            categoryName: _vm.category?.title,
            categorySlug: _vm.category?.slug,
          },
        })}},[(_vm.showCustomBackground && _vm.backgroundUrl)?_c('Img',{staticClass:"absolute inset-0 w-full h-full object-cover",attrs:{"src":_vm.backgroundUrl}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"relative flex flex-col justify-end w-full"},[_c('div',{staticClass:"absolute inset-x-0 top-0 grid grid-cols-[repeat(1,minmax(0,auto))_repeat(1,minmax(0,1fr))] gap-0.5 lg:gap-y-2 lg:gap-4 justify-between z-10"},[(_vm.isAlcohol)?_c('span',{staticClass:"col-span-full block 2xl:hidden"},[_c('ProductAlcoholMessage',{attrs:{"condensed":""}})],1):_vm._e(),_vm._v(" "),_c('span',[(_vm.product?.price)?_c('ProductPriceView',{attrs:{"data":_vm.product?.price}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"2xl:space-y-2"},[(_vm.isAlcohol)?_c('span',{staticClass:"hidden 2xl:block"},[_c('ProductAlcoholMessage',{attrs:{"condensed":""}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex justify-end [&_picture]:justify-end text-right"},[_c('ProductBadges',{attrs:{"product":_vm.product,"type":_vm.BADGES_TYPE.DEFAULT}})],1)])]),_vm._v(" "),_c('div',{class:{
        'space-y-1': !_vm.specialStyles,
        'mt-[68px] md:mt-[108px]': (_vm.product?.price && !_vm.hideInfos) || (!_vm.product?.price && _vm.showPriceSpace),
      }},[_c('div',{staticClass:"flex items-center justify-center",class:{ relative: !_vm.hideInfos }},[_c('span',{staticClass:"absolute bottom-0 right-0 z-10"},[_c('ProductBadges',{class:{
              '-translate-y-[58px] lg:-translate-y-[70px]':
                _vm.hideInfos || _vm.specialStyles,
            },attrs:{"product":_vm.product,"type":_vm.BADGES_TYPE.BIG}})],1),_vm._v(" "),_c('div',{staticClass:"aspect-w-1 md:aspect-w-4 aspect-h-1 md:aspect-h-3 w-full",class:{
            '!absolute top-1/2 -translate-y-1/2 scale-[0.85] md:scale-[1.1] lg:scale-[1.2]':
              _vm.hideInfos || _vm.specialStyles,
          }},[_c('Img',{staticClass:"max-h-full object-center object-contain",attrs:{"src":_vm.productPhoto?.file?.url,"alt":_vm.productPhoto?.alt}})],1)]),_vm._v(" "),_c('div',{staticClass:"h-8 md:h-10 flex items-end"},[_c('h3',{staticClass:"line-clamp-2 text-xs xl:text-base xl:leading-5 font-semibold text-zinc-600",class:{
            'md:text-sm': !_vm.hideInfos && !_vm.specialStyles,
            'lg:!text-sm lg:mb-1': _vm.hideInfos || _vm.specialStyles,
          }},[_vm._v("\n          "+_vm._s(_vm.name)+"\n        ")])]),_vm._v(" "),_c('div',{class:{ 'space-y-1': !_vm.specialStyles }},[(_vm.description)?_c('p',{staticClass:"custom-html text-xs font-medium text-zinc-600 font-lexend"},[_vm._v("\n          "+_vm._s(_vm.description)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.product?.price && !_vm.hideInfos)?_c('ProductPriceDisplay',{attrs:{"product":_vm.product,"hidePerUnit":_vm.product.price.isXyPromo,"hideDiscount":_vm.product.price.isXyPromo,"hideOmnibus":_vm.product.price.isXyPromo,"small":""}}):_vm._e(),_vm._v(" "),_c('ProductBadges',{attrs:{"product":_vm.product,"type":_vm.BADGES_TYPE.BOTTOM}})],1)])]),_vm._v(" "),(_vm.showMoreCard)?_c('p',{staticClass:"absolute top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%] font-poppins font-medium text-center text-lg lg:text-2xl text-zinc-600 w-full max-w-[250px] z-20"},[_vm._v("\n    "+_vm._s(_vm.$t('PRODUCT_CAROUSEL_CARD'))+"\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }